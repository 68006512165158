<template>
    <div class="cart">
        <h3>Contact Us</h3>
        <h6>联系我们</h6>
        <div class="box">
            <div>重庆安标检测研究院有限公司</div>
            <p>业务受理：023-65239416</p>
            <p>样品收发：023-65239415</p>
            <p>报告上传：023-68909962</p>
            <p>收费开票：023-65239404</p>
            <p>报告及发票邮寄：023-65239204</p>
            <p>地址：重庆市大渡口区石林大道6号</p>
            <p>邮编：401325</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'contactUs'
}
</script>
<style scoped>
h6 {
    margin-bottom: 18px;
}
.box div{
    font-size: 20px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 28px;
    margin-bottom: 13px;
}
.box p{
    width: 50%;
    display: inline-block;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
}
</style>